import request from "@/utils/request";

// 韩语APP爱豆列表
export function IdolList(params) {
    return request({
        url: '/admin/korean_v3/idolList',
        method: 'get',
        params
    })
}

// 添加韩语APP爱豆
export function IdolAdd(data) {
    return request({
        url: '/admin/korean_v3/idolAdd',
        method: 'post',
        data
    })
}

// 编辑韩语APP爱豆
export function IdolEdit(data) {
    return request({
        url: '/admin/korean_v3/idolEdit',
        method: 'put',
        data
    })
}

// 删除韩语APP爱豆
export function IdolDel(data) {
    return request({
        url: '/admin/korean_v3/idolDel',
        method: 'delete',
        data
    })
}

// 爱豆上传COS签名
export function getIdolCosToken(params) {
    return request({
        url: '/admin/korean_v3/getIdolCosToken',
        method: 'get',
        params
    })
}